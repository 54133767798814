
// RouterTab 内置路由
let Home = ()=> import('../views/home.vue');

const basicRoutes = [
    {
        path: "/login",
        name: "login",
        title: '登录',
        redirect: '',
        component: ()=> import('../views/login.vue'),
    },
    {
        path: "/",
        name: "index",
        title: '储能智慧消防监管平台',
        redirect: '/index',
    },
    {
        path: "/index",
        name: "index",
        title: '储能智慧消防监管平台',
        // redirect: '/search',
        component: ()=> import('../views/index/Index.vue'),
    },
     //start luoy
    // 储能管理端
    {
        path: "/powerStation",
        name: "powerStation",
        title: '储能电站管理',
        redirect: '/powerStation/powerStationList',
        component: Home,
        children: [
            {
                path: "powerStationList",
                name: "powerStationList",
                title: '储能电站列表',
                meta:{
                    title: '储能电站列表',
                },
                component: ()=> import('../views/storageStationManage/PowerStationList.vue'),
            },
            {
                path: "PowerStationDetail",
                name: "PowerStationDetail",
                title: '储能电站详情',
                meta:{
                    title: '储能电站详情',
                },
                component: ()=> import('../views/storageStationManage/PowerStationDetail.vue'),
            }
        ]
    },
    {
        path: "/stationDetail1",
        name: "stationDetail1",
        title: '储能电站详情',
        redirect: '/stationDetail',
        component: Home,
        children: [
            {
                path: "/stationDetail",
                name: "/stationDetail",
                title: '储能电站详情页',
                meta:{
                    title: '储能电站详情页',
                },
                component: ()=> import('../views/index/StationDetail.vue'),
            }
        ]
    },
    {
        path: "/storageTank",
        name: "storageTank",
        title: '储能舱管理',
        redirect: '/storageTank/storageTankList',
        component: Home,
        children: [
            {
                path: "storageTankList",
                name: "storageTankList",
                title: '储能舱列表',
                meta:{
                    title: '储能舱列表',
                },
                component: ()=> import('../views/storageTankManage/StorageTankList.vue'),
            }
        ]
    },
    {
        path: "/alarmManage",
        name: "alarmManage",
        title: '报警管理',
        redirect: '/alarmManage/alarmList',
        component: Home,
        children: [
            {
                path: "alarmList",
                name: "alarmList",
                title: '报警管理列表',
                meta:{
                    title: '报警管理列表',
                },
                component: ()=> import('../views/alarmManage/AlarmList.vue'),
            }
        ]
    },
    {
        path: "/deviceManage",
        name: "deviceManage",
        title: '设备管理',
        redirect: '/deviceManage/equipmentMgt',
        component: Home,
        children: [
            {
                path: "equipmentMgt",
                name: "equipmentMgt",
                title: '消防设备列表',
                meta:{
                    title: '消防设备列表',
                },
                component: ()=> import('../views/deviceManage/EquipmentMgt.vue'),
            },
            {
                path: "runRecord",
                name: "runRecord",
                title: '运行记录',
                meta:{
                    title: '运行记录',
                },
                component: ()=> import('../views/deviceManage/RunRecord.vue'),
            },
        ]
    }, 
    {
        path: "/sparePartsManage",
        name: "sparePartsManage",
        title: '备件管理',
        redirect: '/sparePartsManage/sparePartsList',
        component: Home,
        children: [
            {
                path: "sparePartsList",
                name: "sparePartsList",
                title: '备件管理列表',
                meta:{
                    title: '备件管理列表',
                },
                component: ()=> import('../views/sparePartsManage/SparePartsList.vue'),
            },
        ]
    }, 
    {
        path: "/systemManage",
        name: "systemManage",
        title: '系统管理',
        redirect: '/systemManage/userManageList',
        component: Home,
        children: [
            {
                path: "userManageList",
                name: "userManageList",
                title: '用户管理',
                meta:{
                    title: '用户管理',
                },
                component: ()=> import('../views/systemManage/UserManageList.vue'),
            },
            {
                path: "pushManageList",
                name: "pushManageList",
                title: '推送管理',
                meta:{
                    title: '推送管理',
                },
                component: ()=> import('../views/systemManage/PushManageList.vue'),
            },
        ]
    }, 
    {
        path: "/personalCenter",
        name: "personalCenter",
        title: '个人中心',
        redirect: '/personalCenter/person',
        component: Home,
        children: [
            {
                path: "person",
                name: "person",
                title: '个人中心',
                meta:{
                    title: '个人中心',
                },
                component: ()=> import('../views/personalCenter/Person.vue'),
            },
        ]
    }, 
    // end luoy
]

export default basicRoutes;